<template>
    <div id="app_loading_wrap">
        <router-view/>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";

export default {
    name: "App",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {

        }
    },
    props: {},
    data() {
        return {
            loading: false,
            loadingText: '',

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('changeLoading', this.changeLoading);
    },
    beforeDestroy() {
        EventBus.$off('changeLoading', this.changeLoading);
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeLoading(value, text='') {
            this.loading = value;
            if(!value) {
                this.loadingText = '';
            }else{
                this.loadingText = text;
            }
            //return this.loading;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 50)
            })
        },
    },
    watch: {},
}
</script>

<style lang="scss">
@import "./assets/scss/index.scss";
</style>
